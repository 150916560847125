import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const AboutMasthead = () => {
  return (
    <section className="hero-section d-flex align-items-center justify-content-center position-relative">
      <Container className="position-relative">
        <Row>
          <Col md={8} className="mx-auto text-center">
            <div className="hero-text">
              <div className="style-1" style={{ marginTop: "10rem" }}>
                Our <span className="color-orange-500"> vision </span> is to
                make work inspiring and fulfilling
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutMasthead;
