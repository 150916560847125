import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import useAOS from "./useAOS";
import useRippleEffect from "./components/useRippleEffect";
import Layout from "./components/layout/Layout";
import HomePage from "./components/pages/Homepage";
import ServicesPage from "./components/pages/ServicesPage";
import AboutPage from "./components/pages/AboutPage";
import ContactPage from "./components/pages/ContactPage";

function App() {
  useAOS();
  useRippleEffect();
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/services" element={<ServicesPage />} /> */}
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
