import React from "react";
import { Container } from "react-bootstrap";

const CodeCompany = () => {
  return (
    <section class="code-company-section aos-fadeup">
      <Container>
        <h2 className="style-3 mb-5 text-center">From Code to Company</h2>
        <p className="body-1 color-neutral-200 text-center">
          Step into the EnvisionX playground – it's not just a company; it's a
          hub of digital dreams! Picture us as the wizards of innovation,
          brewing a concoction of cutting-edge tech, cybersecurity wizardry, and
          a sprinkle of Web3 magic. This isn't your average office. It's a
          universe where possibilities are redefined, with a big smile and a
          dash of fun. Ready to join the friendly revolution?
        </p>
      </Container>
    </section>
  );
};

export default CodeCompany;
