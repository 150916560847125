import React from "react";
import ServiceCard from "../../ServiceCard";
import { Container, Row, Col } from "react-bootstrap";

const OurServices = () => {
  const services = [
    { content: "Cryptogeny", stripColor: "#1FCFF1" },
    { content: "Contractify", stripColor: "#F2C94C" },
    { content: "DAppify", stripColor: "#763FF5" },
    { content: "Systemize", stripColor: "#19FB9B" },
    { content: "Assessify", stripColor: "#763FF5" },
    { content: "PenTestify", stripColor: "#19FB9B" },
    { content: "ThreatIQ", stripColor: "#1FCFF1" },
    { content: "PlanGuard", stripColor: "#F2C94C" },
    { content: "Backendify", stripColor: "#19FB9B" },
    { content: "CodeCraft", stripColor: "#763FF5" },
    { content: "DevOpsify", stripColor: "#F2C94C" },
    { content: "DataCraft", stripColor: "#1FCFF1" },
  ];
  return (
    <div className="container aos-fadeup">
      <div className="our-services-section position-relative">
        {/* <p className="label color-neutral-50 mb-2 text-uppercase">
          OUR SERVICES
        </p> */}
        <div className="style-2 mb-5">
          A <span className="color-orange-500">complete suite</span> of products
          to create and participate in Web3.
        </div>
        <p className="body-1 color-neutral-200">
          Blockchain technology is more than a trend—it's revolutionizing data
          management in business. Our expert blockchain developers are at the
          forefront, guiding you to the cutting edge of this dynamic industry.
        </p>
        <div className="services-cards-container padding-96">
          <Row>
            {services.map((service, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                <ServiceCard
                  content={service.content}
                  stripColor={service.stripColor}
                />
              </Col>
            ))}
          </Row>
        </div>

        {/* <a className="primary-button" href="/services">
          {" "}
          Explore all{" "}
        </a> */}
      </div>
    </div>
  );
};

export default OurServices;
