import React from "react";
import { Container } from "react-bootstrap";

const JoinTeam = () => {
  return (
    <Container className="aos-fadeup">
      <section className="join-team-section text-center">
        <h2 className="style-3 mb-5">
          Join our <span className="color-orange-500"> team </span>
        </h2>
        <p className="color-neutral-200 body-1 text-center mb-5">
          Ready to join the EnvisionX adventure? Throw your hat in the ring by
          sharing your awesome resume! Email it to us at <a href="mailto:carrer@envisionx.io">carrer@envisionx.io </a>
           or, for a one-click journey to exciting possibilities, hit that button
          below. Let the good times (and careers) roll!
        </p>
        <a className="primary-button" href="/contact"> Apply now </a>
      </section>
    </Container>
  );
};

export default JoinTeam;
