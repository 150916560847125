import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../../images/logo.svg";

const Header = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formattedTime = date
    .toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
    .toUpperCase();
  const formattedDate = date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "long",
  });

  return (
    <Navbar
      expand="lg"
      className="justify-content-between px-3 px-lg-5 py-3 py-lg-5"
    >
      <Navbar.Brand href="#" className="d-lg-none">
        <img src={logo} alt="Logo" className="img-fluid"/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" className="custom-toggler"/>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <a href="/" className="nav-link color-white">
            Home
          </a>
          {/* <a href="/services" className="nav-link color-white">
            Services
          </a> */}
          <a href="/about-us" className="nav-link color-white">
            About us
          </a>
          <a href="/contact-us" className="nav-link color-white">
            Contact us
          </a>
          {/* <a href="/blog" className="nav-link color-white">
            Blog
          </a> */}
        </Nav>
        <Nav className="d-block d-lg-none nav-time-container mt-3 text-center">
          <span className="me-3">
            IND {formattedTime} | {formattedDate}
          </span>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Brand
        href="#"
        className="d-none d-lg-block position-absolute top-50 start-50 translate-middle"
      >
        <img src={logo} alt="Logo" />
      </Navbar.Brand>
      <Nav className="d-none d-lg-block">
        <span className="me-3">
          IND {formattedTime} | {formattedDate}
        </span>
      </Nav>
    </Navbar>
  );
};

export default Header;
